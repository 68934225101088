import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';

const FooterContainer = styled.div`
  display: flex;
  padding: 2% 15%;
  margin: auto;
  margin-top: 2%;
  border-top: 2px solid grey;
  background-color: lightgrey;
  @media (max-width: 930px) {
    flex-direction: column;
  }
  a {
    color: #c69300;
  }
`;

const FooterItem = styled.div`
  flex: 1;
  padding: 5%;
  :last-child {
    border-left: 4px solid grey;
    @media (max-width: 930px) {
      border-left: none;
      border-top: 4px solid grey;
    }
  }
`;

const Footer = () => (
  <FooterContainer>
    <StaticQuery
      query={graphql`
        query lawLinks {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "law" } } }
          ) {
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  name
                }
              }
            }
          }
          markdownRemark(frontmatter: { name: { eq: "Kontakt" } }) {
            html
          }
        }
      `}
      render={data => (
        <>
          <FooterItem
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
          <FooterItem>
            <h2>Links</h2>
            {data.allMarkdownRemark.edges.map(l => (
              <div>
                <Link to={l.node.fields.slug}>{l.node.frontmatter.name}</Link>
              </div>
            ))}
          </FooterItem>
        </>
      )}
    />
  </FooterContainer>
);

export default Footer;
