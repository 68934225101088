import styled from 'styled-components';

export const Container = styled.section`
  max-width: 70%;
  margin: auto;
  @media (max-width: 780px) {
    max-width: 90%;
  }
  @media (max-width: 1240px) {
    max-width: 90%;
  }
  h1 {
    padding-top: 2%;
    padding-bottom: 1%;
    border-bottom: 1px #c69300 solid;
  }
  h3 {
    margin-top: 2%;
    font-weight: normal;
    padding-bottom: 1%;
    border-bottom: 1px #c69300 solid;
  }
`;

export const Title = styled.h1`
  padding-top: 2%;
  padding-bottom: 1%;
  border-bottom: 1px #c69300 solid;
`;

export const Subtitle = styled.h3`
  margin-top: 2%;
  font-weight: normal;
  padding-bottom: 1%;
  border-bottom: 1px #c69300 solid;
`;

export const List = styled.ul`
  list-style: none;
  li::before {
    content: '•';
    color: #c69300;
    left: -10px;
    position: relative;
  }
`;

export const Button = styled.button`
  background-color: #c69300;
  border-radius: 4px;
  text-align: center;
  box-shadow: none;
  padding: calc(0.375em - 1px) 0.75em;
  border: 1px solid transparent;
  color: white;
  font-size: 1.25rem;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  :hover {
    transform: scale(1.2);
    background-color: #7f6926;
  }
`;

export const Highlight = styled.span`
  margin-top: 2%;
  font-weight: normal;
  margin-bottom: 2%;
  text-decoration: underline;
  color: #c69300;
`;

export const Modal = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 40;
  > div {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(10, 10, 10, 0.86);
    > div {
      overflow: scroll;
      margin: auto;
      padding: 2%;
      max-width: 70%;
      background-color: white;
      max-height: 80%;
      border-radius: 8px;
      @media (max-width: 780px) {
        max-width: 90%;
      }
      h5 {
        margin-top: 2%;
        font-weight: normal;
        margin-bottom: 2%;
        text-decoration: underline;
        color: #c69300;
      }
    }
  }
`;

export const Gallery = styled.div`
  padding-top: 5%;
  max-width: 530px;
  margin: auto;
  button {
    color: #c69300;
    background-color: transparent;
    ::before {
      color: #c69300;
    }
    :hover {
      color: #c69300;
      background-color: transparent;
    }
  }
`;
