import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { string } from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const HeaderGroup = styled.div`
  text-align: center;
  * {
    max-width: 300px;
    margin: auto;
  }
  .links {
    max-width: 70%;
    display: flex;
    @media (max-width: 780px) {
      flex-direction: column;
      display: ${props => (props.visible ? 'flex' : 'none')};
    }
  }
  .menu {
    cursor: pointer;
    display: none;
    border: none;
    background-color: white;
    color: #c69300;
    text-decoration: none;
    font-size: 1.25rem;
    transition: color 0.25s, font-size 0.25s;
    :hover {
      color: #7f6926;
      font-size: 1.5rem;
    }
    @media (max-width: 780px) {
      display: block;
    }
    svg {
      padding-top: 5%;
    }
  }
`;

const HeaderLink = styled.div`
  flex: 1;
  height: 2rem;
  a {
    color: #c69300;
    text-decoration: none;
    font-size: 1.25rem;
    transition: color 0.25s, font-size 0.25s;
    :hover {
      color: #7f6926;
      font-size: 1.5rem;
    }
  }
`;

class Header extends React.Component {
  constructor() {
    super();
    this.state = { menu: false };
  }

  render() {
    const { siteTitle } = this.props;
    const { menu } = this.state;
    return (
      <HeaderGroup visible={menu}>
        <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(
                relativePath: { eq: "meyka_logo_neu.jpg" }
              ) {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              allMarkdownRemark(
                filter: { frontmatter: { templateKey: { ne: "law" } } }
              ) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    frontmatter {
                      name
                      position
                    }
                  }
                }
              }
            }
          `}
          render={data => (
            <>
              <Link to="/">
                <Img
                  fluid={data.placeholderImage.childImageSharp.fluid}
                  alt={siteTitle}
                />
              </Link>
              <button
                type="button"
                className="menu"
                onClick={() => this.setState({ menu: !menu })}
              >
                Menü {menu ? <FaCaretUp /> : <FaCaretDown />}
              </button>
              <div className="links">
                {data.allMarkdownRemark.edges
                  .map(l => ({
                    slug: l.node.fields.slug,
                    pos:
                      l.node.frontmatter.position ||
                      data.allMarkdownRemark.length + 1,
                    name: l.node.frontmatter.name,
                  }))
                  .sort((a, b) => a.pos - b.pos)
                  .map(l => (
                    <HeaderLink>
                      <Link to={l.slug}>{l.name}</Link>
                    </HeaderLink>
                  ))}
              </div>
            </>
          )}
        />
      </HeaderGroup>
    );
  }
}

Header.propTypes = {
  siteTitle: string,
};

Header.defaultProps = {
  siteTitle: 'Juwelier Meyka',
};

export default Header;
